import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"custom-row",attrs:{"dense":""}},[_c(VCol,{class:_vm.style1,attrs:{"cols":"12"}},[_c('h1',{staticClass:"highlight-municipality-title highlight-main-name",domProps:{"innerHTML":_vm._s(_vm.name)}})]),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VCol,{attrs:{"cols":"12 pb-4 pl-0"}},[_c(VCard,{class:_vm.style2,attrs:{"dark":""}},[_c(VCardTitle,{staticClass:"highlight-title"},[_vm._v(" Extension Territorial ")]),_c(VCardSubtitle,{staticClass:"highlight-value",domProps:{"innerHTML":_vm._s(_vm.highlight1value)}})],1)],1),_c(VCol,{attrs:{"cols":"12 pb-4 pl-0"}},[_c(VCard,{class:_vm.style3},[_c(VCardTitle,{staticClass:"highlight-title"},[_vm._v(" Densidad Poblacional ")]),_c(VCardSubtitle,{staticClass:"highlight-value-secondary",domProps:{"innerHTML":_vm._s(_vm.highlight2value)}})],1)],1),_c(VCol,{attrs:{"cols":"12 pb-4 pl-0"}},[_c(VCard,{class:_vm.style3},[_c(VCardTitle,{staticClass:"highlight-title"},[_vm._v(" Número de distritos ")]),_c(VCardSubtitle,{staticClass:"highlight-value-secondary",domProps:{"innerHTML":_vm._s(_vm.highlight3value)}})],1)],1)],1),_c(VCol,{staticClass:"highlight-image-col",attrs:{"cols":"8"}},[_c('img',{staticClass:"highlight-image",attrs:{"src":_vm.image,"alt":"Header Image"}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }