import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"custom-row",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VCard,{class:_vm.getClass('highlight1'),attrs:{"dark":""}},[_c(VCardTitle,{staticClass:"highlight-title"},[_vm._v(" Extension Territorial ")]),_c(VCardSubtitle,{staticClass:"highlight-value",domProps:{"innerHTML":_vm._s(_vm.information.highlight1.value)}})],1)],1),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VCard,{class:_vm.getClass('highlight-secondary')},[_c(VCardTitle,{staticClass:"highlight-title"},[_vm._v(" Densidad Poblacional ")]),_c(VCardSubtitle,{staticClass:"highlight-value-secondary",domProps:{"innerHTML":_vm._s(_vm.information.highlight2.value)}})],1)],1),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VCard,{class:_vm.getClass('highlight-secondary')},[_c(VCardTitle,{staticClass:"highlight-title"},[_vm._v(" Número de departamentos ")]),_c(VCardSubtitle,{staticClass:"highlight-value-secondary",domProps:{"innerHTML":_vm._s(_vm.information.highlight3.value)}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }