<template>
  <footer class="custom-footer">
    <img src="../../assets/images/footer/footer.png" alt="Footer Image" class="footer-image" />
  </footer>
</template>
  
<script>
  export default {
      components: {
      },
  };
</script>

  